<script setup>
import { computed, onMounted, watch } from 'vue';
import useInput from './useInput';

const {
  handleOnMounted,
  handleOnFocus,
  computedInputAttrs,
  computedIsRequired,
  computedRules,
  input,
  inputValue,
  watchFocus,
  watchModelValue,
} = useInput();

const props = defineProps(myProps);

const inputAttrs = computed(computedInputAttrs(props));
const isRequired = computed(computedIsRequired(props));
const myRules = computed(computedRules(props));

const emit = defineEmits(['update:modelValue']);

// set initial value
inputValue.value = props.modelValue;

watch(inputValue, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    const newModelValue = props.canTrimValue ? String(newValue).trim() : newValue;

    // Handle a special case when the user updates the input value with trailing whitespace that
    // is trimmed above. Because the trimmed model value is the same as the old value, the watch on
    // the modelValue below will not be called and thus the input value won't be updated. So when
    // this case occurs, the input value is manually updated with the trimmed value and the
    // update:modelValue event is not emitted.

    if (props.canTrimValue && newModelValue === oldValue) {
      inputValue.value = newModelValue;
    }
    else {
      emit('update:modelValue', newModelValue);
    }
  }
});
watch(() => props.focus, watchFocus);
watch (() => props.modelValue, watchModelValue);

onMounted(handleOnMounted);
</script>

<script>
// defineProps() cannot reference locally defined variables so the default props from useInput()
// are defined at the module level.
// SEE:
// https://stackoverflow.com/questions/69951687/vue-3-defineprops-are-referencing-locally-declared-variables
// https://eslint.vuejs.org/rules/valid-define-props.html

const { getDefaultProps } = useInput();
const myProps = getDefaultProps();
</script>

<template>
  <Field
    v-slot="{ errors, handleChange, resetField }"
    as="div"
    :model-value="modelValue"
    :name="name"
    :rules="myRules"
    :validate-on-input="true"
  >
    <InputWrapper
      :disabled="disabled"
      :errors="errors"
      :hide-empty-errors="hideEmptyErrors"
      :full-width="fullWidth"
      :label="label"
      :name="name"
    >
      <input
        ref="input"
        v-model="inputValue"
        v-bind="inputAttrs"
        class="tw-inline-block tw-w-full tw-outline-none bg-inherit"
        :class="inputClass"
        :required="isRequired"
        @blur="handleChange"
        @focus="handleOnFocus(resetOnFocus, resetField)"
      >
    </InputWrapper>
  </Field>
</template>
